<template>
	<div class="RegisterWelcome">
		<div class="header">
			<img src="@/assets/img/welcome2.png" alt="">
		</div>
		<div class="body">
			<video src="http://shipin.zyrlzy.cn/hzjh.mp4" controls autoplay></video>
		</div>
		<div class="body-bottom">
			<img src="@/assets/img/welcome3.png" alt="">
		</div>
		<div class="footer">
			<van-button v-if="type == 1" type="primary" round color="#AAA" block>关闭（剩余{{typeTime}}秒）</van-button>
			<van-button v-if="type == 2" type="primary" round color="#3377FF" block @click="toIndex">关闭</van-button>
		</div>
	</div>
</template>

<script>
	import XgPlayer from '@/components/xgplayer.vue';

	export default {
		name: 'RegisterWelcome',
		data() {
			return {
				type: 1,
				typeTime: 30
			}
		},
		created() {
			let timeText = setInterval(() => {
				let time = this.typeTime
				time--
				this.typeTime = time
				if (this.typeTime == 0) {
					clearTimeout(timeText)
					this.type = 2
				}
			}, 1000)
		},
		methods: {
			toIndex() {
				this.$router.replace({
					name: 'Index'
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.RegisterWelcome {
		width: 100%;
		min-height: 100vh;
		background: url('../../assets/img/welcome1.jpg');
		background-size: cover;
		box-sizing: border-box;
		position: relative;

		.header {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 247px;
			
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.body {
			position: absolute;
			top: 260px;
			width: 100%;
			height: 213px;
			border-radius: 8px;
			padding: 0 10px;
			box-sizing: border-box;
			overflow: hidden;
			z-index: 10;

			video {
				width: 355px;
				height: 213px;
				object-fit: cover;
			}
		}
		
		.body-bottom{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 278px;
			
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		
		.footer {
			width: 100%;
			position: absolute;
			bottom: 20px;
			padding: 0 10px;
			box-sizing: border-box;
			z-index: 10;
			
			/deep/.van-button--round {
				border-radius: 100px;
				font-size: 15px;
			}
		}
	}
</style>